import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ICanDeactivateComponent } from "rl-common/components/create-workflow/create-entity.models";
import { ModalService } from "rl-common/services/modal.service";
import { of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UnsavedChangesGuard {
	public constructor(
		private readonly _modalService: ModalService
	) { }

	canDeactivate(component: ICanDeactivateComponent,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		nextState?: RouterStateSnapshot) {

		const canDeactivate = component?.canDeactivate ?? true;
		if (canDeactivate) {
			return of(true);
		}

		if (component.confirmDialog$) {
			return component.confirmDialog$;
		}

		return this.confirmNavigateBack$();
	}

	// Known Angular issue "Deactivation Guard breaks the routing history #13586", allegedly fixed in v12.1.2
	private confirmNavigateBack$() {
		const title = "Leave page?";
		const message = "<h4>Changes you made may not be saved.</h4>";
		return this._modalService.confirm(title, message, "Yes", "No", "lg").pipe(
			map(result => !!result)
		);
	}
}